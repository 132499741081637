<template>
    <v-overlay class="tawk-container">
    </v-overlay>
 
</template>


<script>
export default {
  
    name: "MiAyuda",
    data: () => ({
        // Remove vehiculos from data
    }),
    computed: {
        vehiculos() {
            if (!this.$store.state.user_vehicles) return '';
            return this.$store.state.user_vehicles
                .map(({ brand, model, plates, year }) => 
                    `${brand} ${model} ${year} - ${plates}`
                )
                .join(', ');
        }
    },
    created(){
        this.$store.state.title="Ayuda"
        // Remove any existing script to prevent duplicates
        const existingScript = document.querySelector('script[src*="tawk.to"]');
        if (existingScript) {
            existingScript.remove();
        }
        
        // Add Tawk_API configuration before loading the script
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_API.customStyle = {
            visibility : {
                desktop: {
                    position : 'tr',
                    xOffset: 20,
                    yOffset: 20
                },
                mobile: {
                    position : 'tr',
                    xOffset: 20,
                    yOffset: 20
                }
            }
        };
        
        const script = document.createElement('script')
        script.async = true
        script.src = 'https://embed.tawk.to/677dd4caaf5bfec1dbe8252c/1ih1oedic'
        document.head.appendChild(script)
        
        // Show and maximize widget when component is created
        setTimeout(() => {
            if (window.Tawk_API) {
                // Set visitor name if available from store
                if (this.$store.state.user && this.$store.state.user.nombre) {
                    window.Tawk_API.setAttributes({
                        name: `${this.$store.state.user.nombre} ${this.$store.state.user.apellido_p || ''}`,
                        email: `${this.$store.state.user.username || ''}`,
                        phone: `${this.$store.state.user.telefono_movil || ''}`,
                        vehicles: `${this.vehiculos || '' }`
                    });
                }
                window.Tawk_API.showWidget();
                window.Tawk_API.maximize();
                
                // Disable widget dragging and prevent minimizing
                window.Tawk_API.onLoad = function(){
                    window.Tawk_API.disableDrag();
                };
            }
        }, 1000);
    },
    beforeDestroy() {
        if (this.$socket) {
            this.$socket.disconnect();
        }
        
        // Clean up Tawk.to when component is destroyed
        if (window.Tawk_API) {
            window.Tawk_API.hideWidget();
            window.Tawk_API.minimize();
        }
        
        // Remove the script tag
        const tawkScript = document.querySelector('script[src*="tawk.to"]');
        if (tawkScript) {
            tawkScript.remove();
        }
        
        // Remove any Tawk.to iframes and elements
        const tawkElements = document.querySelectorAll('[id^="tawk-"]');
        tawkElements.forEach(element => element.remove());
    }
}
</script>

<style>
/* Position chat within v-main */
#tawk-iframe-container {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 600px !important;
    width: 400px !important;
    max-height: 80vh !important;
    margin: 20px auto !important;
    padding: 0 !important;
}

iframe#tawk-iframe-widget-main {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

/* Target Tawk widget container and button positioning */
#tawk-default-container {
    top: 20px !important;
    bottom: auto !important;
    right: 20px !important;
    left: auto !important;
}

.tawk-min-container {
    top: 20px !important;
    bottom: auto !important;
    right: 20px !important;
    left: auto !important;
    position: fixed !important;
}

/* Ensure button visibility and positioning */
.tawk-button,
.tawk-min-container,
.tawk-button-circle,
#tawk-default-container[style*="bottom"] {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    position: fixed !important;
    top: 20px !important;
    right: 20px !important;
    bottom: auto !important;
    left: auto !important;
    z-index: 999999 !important;
}

.tawk-widget-container {
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    height: 600px !important;
    width: 400px !important;
    max-height: 80vh !important;
    margin: 20px auto !important;
    padding: 0 !important;
}

.tawk-container {
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}

/* Hide "We are here" button */
.tawk-custom-color {
    display: none !important;
}

.tawk-branding {
    display: none !important;
}

/* Hide any potential custom buttons */
.tawk-custom-text-color {
    display: none !important;
}

.tawk-button-circle {
    display: none !important;
}
</style>



